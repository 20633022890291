import React,{useState} from 'react';
import {
    Input,Textarea ,Checkbox ,Select,Stack
} from '@chakra-ui/react';
import Datetime from 'react-datetime';
import { MultiSelect } from "react-multi-select-component";
import "./productcard.css";
function CustomField({checki,type,register,options,groupname,field_id,PreFilled,setValue,preFillMulti}) {
    let newArray = [];
    const [selected, setSelected] = useState(newArray);
    let button;
    if(type == 1 || type == 2 || type == 7 || type == 8 || type == 4 || type == 3){
        button = (
            <>
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][type]`)} value={type} />
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][id]`)} value={field_id}/>
                <Input type="text"  className="form-control"  {...register(`txt_product_search[${checki}][box]`)} /> 
            </>
        )
    }else if(type == 6){
        button = (
            <>
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][type]`)} value={type} />
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][id]`)} value={field_id}/>
                <Input type="text"  className="form-control"  {...register(`txt_product_search[${checki}][box]`)} /> 
            </>
        )
    }else if(type == 5){
        button = (
            <>
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][type]`)} value={type} />
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][id]`)} value={field_id}/>
                <Input type="date"  className="form-control"  {...register(`txt_product_search[${checki}][box]`)} /> 
            </>
        )
    }else if(type == 9){
        button = (
            <>
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][type]`)} value={type} />
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][id]`)} value={field_id}/>
                <Textarea className="form-control" placeholder="Leave a comment here" {...register(`txt_product_search[${checki}][box]`)} />
             </>
        )
    }else if(type == 10){
        button = (
        <>
            <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][type]`)} value={type} />
            <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][id]`)} value={field_id}/>
            <Stack spacing={5} direction='row'>
            {options.map((opt,index) => (
                <div className="form-check form-check-inline" key={index}>
                    <Checkbox  className="form-check-input"  value={opt.ID} id="flexCheckDisabled" {...register(`txt_product_search[${checki}][box][]`)} >
                        {opt.Value}
                    </Checkbox>
                </div>
            ))}
            </Stack>
        </>
        )
    }else if(type == 11){
        button = (
        <>
            <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][type]`)} value={type} />
            <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][id]`)} value={field_id}/>
            <Select  className="form-select" {...register(`txt_product_search[${checki}][box]`)} >
            <option value="">Select</option>
            {options.map((opt,index) => (
                <option  key={index} value={opt.ID}>{opt.Value}</option>
            ))}
            </Select>
        </>
        )
    }else if(type == 12){
    //     let arr1 = options.map(item => {
    //     return {
    //         'label' : item.Value,
    //         'value' : item.ID
    //     }
    //     });
    //     button = (
    //     <Stack zIndex={99999}>
    //         <Input type="hidden" value={JSON.stringify(selected)}   {...register(`customs_${field_id}`)} />
    //         <MultiSelect
    //             options={arr1}
    //             value={selected}
    //             onChange={setSelected}
    //             labelledBy="Select"
    //         />
    //     </Stack>
    //   )
        button = (
            <>
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][type]`)} value={type} />
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][id]`)} value={field_id}/>
                <Stack spacing={5} overflowY={'scroll'} height={'200px'}>
                {options.map((opt,index) => (
                    <div className="form-check form-check-inline" key={index}>
                        <Checkbox  className="form-check-input"  value={opt.ID} id="flexCheckDisabled" {...register(`txt_product_search[${checki}][box][]`)} >
                            {opt.Value}
                        </Checkbox>
                    </div>
                ))}
                </Stack>
            </>
            )
    }else if(type == 13){
        button = (
            // <>
            //     <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][type]`)} value={type} />
            //     <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][id]`)} value={field_id}/>
            //     <RadioGroup>
            //         <Stack direction='row'>
            //             {options.map((opt,index) => (
            //                 <div className="form-check form-check-inline" key={index}>
            //                     <Radio 
            //                         className="form-check-input" 
            //                         type="radio" 
            //                         name="exampleRadios"  
            //                         value={opt.ID} 
            //                         {...register(`customs_${field_id}`)} 
            //                     >
            //                     {opt.Value}
            //                     </Radio>
            //                 </div>
            //             ))}
            //         </Stack>
            //     </RadioGroup>
            // </>
            <>
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][type]`)} value={type} />
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][id]`)} value={field_id}/>
                <Stack spacing={5} overflowY={'scroll'} height={'200px'}>
                {options.map((opt,index) => (
                    <div className="form-check form-check-inline" key={index}>
                        <Checkbox  className="form-check-input"  value={opt.ID} id="flexCheckDisabled" {...register(`txt_product_search[${checki}][box][]`)} >
                            {opt.Value}
                        </Checkbox>
                    </div>
                ))}
                </Stack>
            </>
        )
    }else if(type == 14){
        button = 
        (
            <>
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][type]`)} value={type} />
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][id]`)} value={field_id}/>
                <Input type="time" className="form-control"  {...register(`txt_product_search[${checki}][box]`)} />
            </>
        )
    }else if(type == 15){
        button = (
            <>
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][type]`)} value={type} />
                <Input type="hidden"  className="form-control"  {...register(`txt_product_search[${checki}][id]`)} value={field_id}/>
                <Input type="datetime-local" className="form-control"  {...register(`txt_product_search[${checki}][box]`)} />
            </>
        )
    }
    return(
        <>
           {button} 
        </>
    )
}
export default CustomField;