// src/redux/productSlice.js
import { createSlice } from '@reduxjs/toolkit';
const productSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        setcatProducts: [],
        setProducts: [],
    },
    reducers: {
        addToProducts: (state, action) => {
            state.products = action.payload ;
            state.setProducts = action.payload ;
            state.setcatProducts = action.payload ;
        },
        filterProduct: (state, action) => {
            const filterItem = state.setcatProducts.filter((item) => item.name.replace(/\s+/g, '-').toLowerCase().includes(action.payload.replace(/\s+/g, '-').toLowerCase()));
            state.setProducts = filterItem;
        },
        filterProductCategory: (state, action) => {
            const removeProdItem = state.products.filter((item) => item.category_name.replace(/\s+/g, '-').toLowerCase().includes(action.payload.replace(/\s+/g, '-').toLowerCase()));
            state.setProducts = removeProdItem;
            state.setcatProducts = removeProdItem;
        },
        filterProductByPrice: (state, action) => {
            //console.log(action.payload);
            if(action.payload == 1){
                const filterItem = state.products.sort((a, b) => a.price1_min - b.price1_min);
                state.setProducts = filterItem;
            }else if(action.payload == 2){
                const filterItem = state.products.sort((a, b) => a.price1_min - b.price1_min).reverse();
                state.setProducts = filterItem;
            }
        },
    },
});

export const productReducer = productSlice.reducer;
export const {
    addToProducts,
    filterProduct,
    filterProductCategory,
    filterProductByPrice
} = productSlice.actions;