import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react';

const Popup = ({ isOpen, onClose, onReturnToCart }) => {
  return (
    <Modal closeOnOverlayClick={false}  isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="2xl" fontWeight="bold">
          Confirmation
        </ModalHeader>
        <ModalBody>
          Are you sure you want to return to the cart? Your changes may not be saved.
        </ModalBody>
        <ModalFooter>
          <Button size="sm" colorScheme="blue" mr={3} onClick={onClose}>
            Stay in Checkout
          </Button>
          <Button size="sm" colorScheme="yellow" onClick={onReturnToCart}>
            Return to Cart
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Popup;