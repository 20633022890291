import React,{useEffect,useState} from 'react';
import { Header } from '../header/Header';
import Footer from '../Footer/Footer';
import http from "../http-common";
import { useForm } from "react-hook-form";
import swal from 'sweetalert';
import {
    Box,
    Button,
    Stack,
    Wrap,Heading,
    FormControl,
  FormLabel,Input,Select, WrapItem,Center,Textarea
  } from '@chakra-ui/react'
  import { useNavigate } from 'react-router-dom';
import MapComponent from '../Basic/MapComponent';
import { useSelector } from 'react-redux';
export const Profile = () => {
    const { register,setValue, formState: { errors }, handleSubmit } = useForm();
    const [country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [selcountry, setSelCountry] = useState("");
    const [seladdress, setSelAddress] = useState("");
    const [btndisable,setButtonDisable] = useState(false);
    const [addcontact, setAddContact] = useState({ name: "", mobile: "", email: "", address1: "", address2: "", country: "", state: "", city: "", zipcode: "", gstnumber: "" });
    const companysetting = useSelector(state => state.company);
    const appgoogID =
    companysetting.length > 0 && companysetting[0].DATA.APPGOOG_ID != null
      ? companysetting[0].DATA.APPGOOG_ID
      : "";
    const shipping_city_array = companysetting.length > 0 && companysetting[0].DATA.shipping_city.length > 0 ? companysetting[0].DATA.shipping_city : "";
    

    let navigate = useNavigate();
    const onSubmit = data =>{
        setButtonDisable(true);
        http.post(`/ecommerce_api/updateContact/`, {
            contact: data,
        }).then(res => {
            if (res.data[0].STATUS == "SUCCESS") {
                setButtonDisable(false);
                swal("Profile updated succesfully.");
            } else {
                setButtonDisable(false);
            }
        }).catch(err => {
            swal("Something went wrong", "error");
            setButtonDisable(false);
        });
    } 
    
    useEffect(()=>{
        window.scrollTo(0, 0);
        const spinner = document.getElementById("spinner");
        if(spinner){
          setTimeout(() => {
            spinner.style.display = "none";
          }, 1500);
        }
    },['']);

    const getCountryList = e => {
        async function findCountryList() {
            let url = '/expo_access_api/getCountryList/';
            const res = await http.post(url, {
                AUTHORIZEKEY: "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy",
            });
            if (res.data[0].STATUS == "SUCCESS") {
                setCountry(res.data[0].DATA);
                setValue("country","India");
                changestate(e,"India");
            } else {
                console.log("undone")
            }
        }
        findCountryList();
    }
    const changestate = (e, val) => {
        var element_val = val == 0 ? e.target.value : val;
        setSelCountry(element_val);
        setAddContact({ ...addcontact, country: element_val });
        async function getStates() {
            const res = await http.post("/expo_access_api/getstatelist/", {
                AUTHORIZEKEY: "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy",
                COUNTRY: element_val,
            });
            if (res) {
                if (res.data[0].STATUS === "SUCCESS") {
                    if (res.data[0].DATA.state) {
                        setState(res.data[0].DATA.state);
                        setValue("state","GUJARAT");
                    }
                } else {
                    swal("Not Found", "State Not Found", "danger");
                    setState([]);
                }
            }
        }
        getStates();
    };
   
    useEffect(() => {
        getCountryList();
    }, []);
    
    useEffect(()=>{
        if(localStorage.getItem("contact_id") > 0){
            async function getContactDetails() {
                const res = await http.post("/ecommerce_api/getContactDetails/", {
                    AUTHORIZEKEY: "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy",
                    contact_id: localStorage.getItem("contact_id"),
                });
                if (res) {
                    if (res.data[0].STATUS === "SUCCESS") {
                        setValue("contact_id",res.data[0].DATA.contact_id);
                        setValue("name",res.data[0].DATA.name);
                        setValue("email",res.data[0].DATA.email_address);
                        setValue("address1",res.data[0].DATA.address_1);
                        setValue("latitude",res.data[0].DATA.contact_lat);
                        setValue("longitude",res.data[0].DATA.contact_lng);
                        setValue("country",res.data[0].DATA.country);
                        setValue("state",res.data[0].DATA.state);
                        setValue("city",res.data[0].DATA.city_name);
                        setValue("zipcode",res.data[0].DATA.zipcode);
                        setValue("gstnumber",res.data[0].DATA.gstno);
                        setValue("address_id",res.data[0].DATA.address_id);
                        setSelAddress(res.data[0].DATA.address_1);
                        changestate(1,res.data[0].DATA.country);
                    }else{
                        swal("Contact Not Found");
                    }
                }
            }
            getContactDetails();
        }else{
            navigate(`/`);
        }
    },[''])
    
  return localStorage.getItem("contact_id") > 0 ? (
    <>
        <Header />
            <Box
                my={10}
                borderWidth='1px' 
                rounded='md'
                maxW="4xl"
                mx="auto"
                className="Ecomm-Group"
                //p={4}
                //background={"gray.200"}
                px={{
                    base: '4',
                    md: '8',
                    lg: '12',
                }}
                py={{
                    base: '6',
                    md: '8',
                    lg: '12',
                }}
            >
                {/* <Breadcrumb  separator={<ChevronRightIcon color='gray.500' />}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href='#'>Your Account</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href='#'>Profile & Security</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb> */}
                <Stack spacing={6}>
                    <Heading as='h1' size='lg' noOfLines={1}>
                        Profile & Security
                    </Heading>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack
                            spacing="6"
                            direction={{
                                base: 'column',
                                md: 'row',
                            }}
                        >
                            <FormControl id="name" isRequired>
                                <FormLabel ><b>Name</b></FormLabel>
                                <Input size="sm" placeholder='Name'  bg={'gray.100'} {...register("name", { required: true})} />
                                <Input type="hidden" size="sm" placeholder='Name'  bg={'gray.100'} {...register("contact_id", { required: true})} />
                            </FormControl>
                            <FormControl id="email">
                                <FormLabel><b>Email Address</b></FormLabel>
                                <Input size="sm" type='email' placeholder='Email Address'  bg={'gray.100'} {...register("email")} />
                                <Input type='hidden'   bg={'gray.100'} />
                            </FormControl>
                        </Stack>
                        <Stack mt={5} mb={3}>
                            {errors.address1 && <span className="error" style={{color:'red'}}>{errors.address1.message}</span>}
                            {errors.latitude && <span className="error" style={{color:'red'}}>{errors.latitude.message}</span>}
                            <MapComponent register={register} setValue={setValue} appgoogID={appgoogID} seladdress={seladdress} />
                        </Stack>
                        <Stack
                            spacing="3"
                            direction={{
                                base: 'column',
                                md: 'row',
                            }}
                        >
                            <FormControl id="address1" isRequired> 
                                <FormLabel ><b>Address</b></FormLabel>
                                <Textarea  type='text'  size="sm" placeholder='Addrss Line 1' bg={'gray.100'}  {...register("address1", { required: 'please enter the address where the delivery is required' })}/>
                                <Input type='hidden' size="sm" placeholder='Client Latitude' bg={'gray.100'}  {...register("latitude", { required: 'please add the address and press the select button for fetching the correct address' })} readOnly/>
                                <Input type='hidden' size="sm" placeholder='Client Longitude' bg={'gray.100'}  {...register("longitude", { required: 'please add the address and press the select button for fetching the correct address' })} readOnly/>
                            </FormControl>
                        </Stack>
                        {/* <Stack
                            spacing="6"
                            direction={{
                                base: 'column',
                                md: 'row',
                            }}
                        >
                            <FormControl id="address1">
                                <Input type='hidden' size="sm" placeholder='Addrss Line 1' bg={'gray.100'} {...register("address1", { required: 'please enter the address where the delivery is required' })}  readOnly/>
                                <Input  type="hidden" size="sm"  bg={'gray.100'} {...register("address_id", { required: true })}  readOnly/>
                            </FormControl>
                            <FormControl id="latitude">
                                <Input type='hidden'  size="sm" placeholder='Client Latitude' bg={'gray.100'} {...register("latitude", { required: 'please add the address and press the select button for fetching the correct address' })} readOnly/>
                            </FormControl>
                            <FormControl id="longitude">
                                <Input type='hidden' size="sm" placeholder='Client Longitude' bg={'gray.100'} {...register("longitude", { required: 'please add the address and press the select button for fetching the correct address' })} readOnly/>
                            </FormControl>
                        </Stack> */}
                        <Stack
                            spacing="6"
                            direction={{
                                base: 'column',
                                md: 'row',
                            }}
                        >
                            <FormControl id="country" isRequired>
                                <FormLabel ><b>Country</b></FormLabel>
                                <Select placeholder='Select Country'
                                    size='sm'
                                    bg={'gray.100'}
                                    {...register("country", { required: true })}
                                    onChange={(e) => { changestate(e, 0) }}
                                >
                                    {country.map((cntry) => (
                                        <option key={cntry.Country.country_code} value={cntry.Country.country_name}>
                                            {cntry.Country.country_name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl id="state" isRequired>
                                <FormLabel><b>State</b></FormLabel>
                                <Select
                                    placeholder='Select State'
                                    size='sm'
                                    bg={'gray.100'}
                                    {...register("state", { required: true })}
                                    onChange={(e) => { setAddContact({ ...addcontact, state: e.target.value }) }}
                                >
                                    {State
                                        ?
                                        [State.map((S) => (
                                            <option key={`state_${S.State.state_name}`} value={S.State.state_name}>{S.State.state_name}</option>
                                        ))]
                                        : ""
                                    }
                                </Select>
                            </FormControl>
                            <FormControl id="city" isRequired>
                                <FormLabel><b>City</b></FormLabel>
                                {shipping_city_array.length > 0 ? (
                                    <Select size='sm' bg={'gray.100'} placeholder='Select City' {...register("city", { required: true })} >
                                        {shipping_city_array.map((city, index) => (
                                            <option key={index} value={city}>
                                            {city}
                                            </option>
                                        ))}
                                    </Select>
                                ) : <Input size="sm" placeholder='City' bg={'gray.100'} {...register("city", { required: true })}  />}
                            </FormControl>
                        </Stack>
                        <Stack
                            spacing="6"
                            direction={{
                                base: 'column',
                                md: 'row',
                            }}
                        >
                            {/* <FormControl id="zipcode" isRequired>
                                <FormLabel ><b>Zipcode</b></FormLabel>
                                <Input maxLength={6} size="sm" placeholder='Zipcode' bg={'gray.100'} {...register("zipcode")} onKeyPress={(event) => {
                                    if (!/^[+-]?\d*(?:[.,]\d*)?$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                            </FormControl> */}
                            {/* <FormControl id="gstnumber">
                                <FormLabel><b>Gst Number</b></FormLabel>
                                <Input size="sm" placeholder='Gst Number' bg={'gray.100'}  {...register("gstnumber")} />
                            </FormControl> */}
                        </Stack>
                       
                        <Wrap spacing='30px' justify='center' mt="4">
                            <WrapItem>
                                <Center>
                                    <Button colorScheme='teal' size='md' type="submit" >
                                        Update
                                    </Button>
                                </Center>
                            </WrapItem>
                        </Wrap>
                    </form>
                </Stack>
            </Box>
        <Footer/>
    </>
  ) : ( <>
    <Header />
            <Box
                my={10}
                minH={500}
                borderWidth='1px' 
                rounded='md'
                maxW="4xl"
                mx="auto"
                className="Ecomm-Group"
                //p={4}
                //background={"gray.200"}
                px={{
                    base: '4',
                    md: '8',
                    lg: '12',
                }}
                py={{
                    base: '6',
                    md: '8',
                    lg: '12',
                }}
            >
                Login Required
            </Box>
            <Footer/>
  </>)
}
