import React, { useEffect, useState } from 'react'
import {
   Container,
    Grid,
    GridItem,
    Box,
    Skeleton
} from '@chakra-ui/react';
import Card from './Card';
import DynamicButton from './DynamicButton';
import { useSelector } from 'react-redux';
import { Header } from '../header/Header';
import Footer from '../Footer/Footer';
const Feature = ({ heading,image_path }) => {
    return (
        <GridItem>
            <Card name={heading} image_path={image_path} />
        </GridItem>
    );
};
export default function Category() {
    document.title = 'Home/Category';
    const category_arr = useSelector((state) => state.category.category);
    const setcategory_arr = useSelector((state) => state.category.setCategory);
    const [category, setCategory] = useState([]);
    const [selcategory, setSelCategory] = useState([]);
    const [categoryname, setCategoryName] = useState("");
    const baseurl = useSelector(state => state.baseurl.baseURL);; //window.location.origin; // //"https://gadgets.ondemandcrm.co"; //"http://hofficelocal";
    const [isLoaded, setIsLoaded] = React.useState(false);
    
    useEffect(() => {
        const spinner = document.getElementById("spinner");
        if(spinner){
            //setTimeout(() => {
            spinner.style.display = "none";
           // }, 1500);
        }
        window.scrollTo(0, 0);
        setCategory(category_arr);
        setSelCategory(setcategory_arr);
        setIsLoaded(true);
    }, [setcategory_arr]);
    return (
        <>
            <Header/>
            <DynamicButton />
            <Box as={Container} maxW="7xl" mt={10} p={12} minH={'580px'}>
                <Grid
                    templateColumns={{
                        base: 'repeat(1, 1fr)',
                        sm: 'repeat(2, 1fr)',
                        md: 'repeat(4, 1fr)',
                    }}
                    gap={{ base: '8', sm: '12', md: '16' }}>
                    {selcategory.length > 0 && selcategory.map((e) => {
                        return (
                            <Skeleton key={e.value} isLoaded={isLoaded}>
                                <Feature
                                    key={e.label}
                                    heading={e.label}
                                    image_path={e.image_path}
                                />
                            </Skeleton>
                        );
                    })}
                </Grid>
            </Box>
           <Footer/>
        </>
    )
}
