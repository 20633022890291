import React, { useEffect } from 'react';
import { Box,Image,Flex } from '@chakra-ui/react';
import { Header } from '../header/Header';
import Footer from '../Footer/Footer';
import { useSelector } from 'react-redux';

export const NotFound = () => {
    const base_url = useSelector(state => state.baseurl.baseURL);; //window.location.origin;  //"https://gadgets.ondemandcrm.co"; 
    useEffect(()=>{
        const spinner = document.getElementById("spinner");
        if(spinner){
          spinner.style.display = "none";
        }
    },['']);
    return (
        <>
            <Header/>
                <Box
                    maxW="8xl"
                    mx="auto"
                    px={{
                        base: '4',
                        md: '8',
                        lg: '12',
                    }}
                    py={{
                        base: '6',
                        md: '8',
                        lg: '12',
                    }}
                >
                    <Flex h="60vh" justifyContent="center" alignItems="center">
                        <Image src={`${base_url}/public/images/pagenotfound.png`} alt='Page Not Found' />
                    </Flex>
                </Box>
            <Footer/>
        </>
    )
}
