import React,{useEffect,useState} from 'react';
import { Card, CardBody,Heading,Button,Box,
    SimpleGrid,Image,FormControl,FormLabel,Input,FormHelperText  } from '@chakra-ui/react'
import { useForm } from "react-hook-form";
import http from "../http-common";
import ContactCustomfield from '../Basic/ContactCustomfield';
import serialize from 'form-serialize';
import axios from 'axios'; 
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { Header } from '../header/Header';
import Footer from '../Footer/Footer';
import { useSelector,useDispatch } from 'react-redux'
import {changeCategoryLabel} from '../redux/slices/categorySlice';

export default function CustomizeCake() {
    const { register,handleSubmit,setValue,formState: { errors },} = useForm();
    const [customs,setCustom] = useState([]);
    const baseurl = useSelector(state => state.baseurl.baseURL);//window.location.origin;  //"https://gadgets.ondemandcrm.co";  //"https://ecommerce.ondemandcrm.co";
    let navigate = useNavigate();
    const IMAGE = `${baseurl}/public/images/MedBot/cakeoffer1.png`;
    const dispatch = useDispatch();
    useEffect(()=>{
        window.scrollTo(0, 0);
        dispatch(changeCategoryLabel(""));
        const spinner = document.getElementById("spinner");
        if(spinner){
          setTimeout(() => {
            spinner.style.display = "none";
          }, 1500);
        }
      },['']);
    async function getLeadCustomList() {
        let url = '/ecommerce_api/getLeadCustomFieldCake/';
        const res = await http.post(url, {
            AUTHORIZEKEY: "",
            category_name: '',
        });
        if (res.data[0].STATUS == "SUCCESS") {
            setCustom(res.data[0].DATA.customfields);
        } else {
            setCustom([]);
        }
    }
    
    useEffect(()=>{
        window.scrollTo(0, 0);
    },['']);
    useEffect(() => {
        getLeadCustomList();
    }, ['']);
    const onSubmit = data => {
        async function submitProfile() {
            let urls = baseurl + '/ecommerce_api/saveCustomizeCakeDetails/';
            const headers = {
                'content-type': 'multipart/form-data' 
            };
            const form = document.querySelector('#agenda-form');
            const obj = serialize(form, { hash: true });
            const fd = new FormData();
            Object.keys(obj).forEach(function (key) {
                fd.append(key, (obj[key]));
            });
            if (data.photo.length > 0) {
                fd.append('photo', data.photo[0], data.photo[0].name);
            }
            fd.append("contact_id", localStorage.getItem("contact_id"));
            const ress = await axios.post(urls, fd, headers);
            if (ress.data[0].STATUS === "SUCCESS") {
                swal("Success ", ress.data[0].MSG, "success");
                //#===== /ecommerce Remove
                navigate("/category");
            }else{
                //swal("danger", ress.data[0].MSG, "error");
                //setDisable(false);
            }

        }
        submitProfile();
    };
    return (
        <>
            <Header/>
            <Box w='100%' p={10} color='white'>
                <SimpleGrid columns={{sm: 1, md: 2}} spacing={10}>
                    <Box>
                        <form onSubmit={handleSubmit(onSubmit)} id="agenda-form">
                            <Card>
                                <CardBody>
                                    <Heading as='h4' size='md' mb={4}>
                                        Order Custom Cake
                                    </Heading>
                                    <FormControl>
                                        <FormLabel>Upload a reference photo of a cake (Similar to which you want your cake to be made):</FormLabel>
                                        <Input  type="file" {...register("photo")}/>
                                        <FormHelperText>File size between 100 KB - 10 MB. Only JPG, JPEG, PNG.</FormHelperText>
                                    </FormControl>
                                    <ContactCustomfield setValue={setValue} regi={register} customfield={customs} />
                                    <Button borderRadius={0} colorScheme='green' size='md' type='submit'>Send Inquiry</Button>
                                </CardBody>
                            </Card>
                        </form>
                    </Box>
                    <Box>
                        <Image
                            width={'75%'}
                            objectFit='cover'
                            src={IMAGE}
                            alt='Cake Offer'
                        />    
                    </Box>
                </SimpleGrid>
            </Box>
            <Footer/>
        </>
    )
}
