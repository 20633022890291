// src/redux/categorySlice.js
import { createSlice } from '@reduxjs/toolkit';
const categorySlice = createSlice({
    name: 'category',
    initialState: {
        category: [],
        setCategory: [],
        label: localStorage.getItem("category_label") != "" ? localStorage.getItem("category_label") : ""
    },
    reducers: {
        addToCategory: (state, action) => {
            state.category = action.payload ;
            state.setCategory = action.payload ;
        },
        filterCategory: (state, action) => {
            const removeItem = state.category.filter((item) => item.label.toLowerCase().includes(action.payload.toLowerCase()));
            state.setCategory = removeItem;
        },
        changeCategoryLabel: (state, action) => {
            state.label = action.payload;
            localStorage.setItem("category_label",state.label);
        },
    },
});

export const categoryReducer = categorySlice.reducer;
export const {
    addToCategory,
    filterCategory,
    changeCategoryLabel
} = categorySlice.actions;