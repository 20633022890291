// src/redux/categorySlice.js
import { createSlice } from '@reduxjs/toolkit';
const coordinateSlice = createSlice({
    name: 'coordinate',
    initialState: {
        coordinate: JSON.parse(localStorage.getItem('objectData')) || {},
    },
    reducers: {
        addToCoordinate: (state, action) => {
            state.coordinate = action.payload;
            localStorage.setItem('objectData', JSON.stringify(action.payload));
        },
        removeCoordinate: (state, action) => {
            state.coordinate = {};
        }
    },
});

export const coordinateReducer = coordinateSlice.reducer;
export const {
    addToCoordinate,
    removeCoordinate,
} = coordinateSlice.actions;