import React,{ useState } from 'react';
import {
  Image,Box
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import Popup from '../Basic/Popup';
export default function Logo({type}) {
  const companysetting  = useSelector((state) => state.company);
  let navigate = useNavigate();
  const base_url = useSelector(state => state.baseurl.baseURL);
  const base_hostname = useSelector(state => state.baseurl.basehostname);//"bharatkrishna.ondemandcrm.co";  //window.location.hostname;  //window.location.hostname;
  const [isPopupOpen, setPopupOpen] = useState(false); 
  //()=> navigate("/")
  const handleReturnToCart = () => {
    // Navigate to the home page here. You can use a library like react-router-dom for routing.
    // Example: history.push('/home');
    navigate("/cart")
  };

  const handleSubmit = () => {
    // Show the popup when the image is clicked
    if(type > 0){
      setPopupOpen(true);
    }else{
      navigate("/")
    }
    
  };
  return (
    <>
      <Box
        width={{ base: "150px", md: "auto" }} 
        margin="0 auto" 
      >
      {companysetting.length > 0 &&
      <Image
          onClick={handleSubmit}
          style={{cursor:"pointer",maxWidth: "100%",maxHeight: "65px",width:"auto",height:"auto"}}
          src={companysetting[0].DATA.company_original_logo != null ? `${companysetting.length > 0 ? companysetting[0].DATA.company_original_logo : ""}` : `${base_url}/public/ecommerce_portal/shopsynclogoorginal.png`}
          alt='Logo'
        />
      }
      <Popup  isOpen={isPopupOpen}
        onClose={() => setPopupOpen(false)}
        onReturnToCart={handleReturnToCart} />
      </Box>
    </>
  )
}
