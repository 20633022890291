import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import http from "../http-common";
export default function Card({ name,image_path }) {
  let navigate = useNavigate();
  const baseurl = useSelector(state => state.baseurl.baseURL);; //window.location.origin;  //"https://gadgets.ondemandcrm.co";
  const IMAGE_NOPROD = `${baseurl}/viewimage/getcategory/${image_path}`;
  return (
    <Center style={{ cursor: "pointer" }} >
      <Box
        key={name}
        role={'group'}
        p={6}
        maxW={'250px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'lg'}
        pos={'relative'}
        //zIndex={-999}
        //#==== remove /ecommerce
        onClick={() => { navigate(`/filter/${name.replace(/\s+/g, '-')}`); }}
        
      >
        <Box
          rounded={'lg'}
          mt={-10}
          pos={'relative'}
          height={'150px'}
          _after={{
            transition: 'all .3s ease',
            content: '""',
            w: 'full',
            h: 'full',
            pos: 'absolute',
            top: 5,
            left: 0,
            backgroundImage: `url(${IMAGE_NOPROD})`,
            filter: 'blur(15px)',
            zIndex: -1,
          }}
          _groupHover={{
            _after: {
              filter: 'blur(20px)',
            },
          }}>
          <Image
            rounded={'lg'}
            height={150}
            width={200}
            objectFit={'cover'}
            src={IMAGE_NOPROD}
          />
        </Box>
        <Stack pt={10} align={'center'}>
          <Heading textAlign={'center'} fontSize={'15'} fontFamily={'body'} fontWeight={600}>
            {name}
          </Heading>
        </Stack>
      </Box>
    </Center>
  );
}
