  import {
  Box,Center,Flex,HStack,useColorModeValue as mode,
  VisuallyHidden,Modal,ModalOverlay,ModalContent,ModalBody,useDisclosure,
  Drawer,DrawerBody,DrawerHeader,DrawerOverlay,DrawerContent,
  DrawerCloseButton,Link,OrderedList,ListItem,Heading,Stack,useColorModeValue, Text,
  IconButton, Collapse,
  Icon,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import * as React from 'react'
import { AiOutlineUser,AiOutlineCaretDown } from 'react-icons/ai'
import { MdMenu } from 'react-icons/md'
import { RiHeartLine, RiShoppingCartLine } from 'react-icons/ri'
import { CurrencySelect } from './CurrencySelect'
import  Logo  from './Logo'
import { CartCount } from './CartCount'
import { MobileBottomNav } from './MobileBottomNav'
import { NavAction } from './NavAction'
import { NavCategoryMenu } from './NavCategoryMenu'
import { NavCategorySubmenu } from './NavCategorySubmenu'
import { SearchInput } from './SearchInput'
import Login from '../component/Login'
import Logout from '../component/Logout'
import { useDispatch, useSelector } from 'react-redux';

export const Header = () => {
    const { isOpen : isModelOpen, onOpen : isModelOnOpen, onClose : isModelOnClose } = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure();
    let navigate = useNavigate();
    const cart = useSelector((state) => state.cart);
    const category_arr = useSelector((state) => state.category.category);
    const cartlabel  = useSelector((state) => state.cart.label);
    const companysetting  = useSelector((state) => state.company);
    const maincolor = (companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null) ? companysetting[0].DATA.theme_maincolor : "#7c1c1c";
    const fontcolor = (companysetting.length > 0 && companysetting[0].DATA.theme_fontcolor != null) ? companysetting[0].DATA.theme_fontcolor : "#FFF";
    const base_url = useSelector(state => state.baseurl.baseURL); 
    // useSelector(state => state.baseurl.baseURL);; //window.location.origin;  //"https://gadgets.ondemandcrm.co"; //
    const base_hosturl =  useSelector(state => state.baseurl.basehostname);
    // "bharatkrishna.ondemandcrm.co";    //window.location.hostname;  //"gadgets.ondemandcrm.co"; //window.location.hostname;

    const getTotalQuantity = () => {
      let total = 0
      if(cart.cart.length > 0){
        cart.cart.forEach(item => {
          total += item.quantity
        })
      }
      return total
    }
    return(
    <>
    <Flex
      direction="column"
      pb="0rem"
      overflow="hidden"
      display={{
        base: 'flex',
        lg: 'none',
      }}
    >
      {/* bg="#800000" add by ankit for background */}
      <Box bg={maincolor} px="4" py="4" borderBottomWidth="1px" overflow="auto">
        <Flex
          align="center"
          justify="space-between"
          mb="3"
          display={{
            base: 'flex',
            lg: 'none',
          }}
        >
          <HStack spacing="3">
            <Center w="8" h="8" as="button" type="button">
              <VisuallyHidden>Toggle Menu</VisuallyHidden>
              {/* color="#fff" add by ankit for background */}
              <Box color="#fff" as={MdMenu} fontSize="3xl" onClick={onOpen} />
              <Drawer
                  isOpen={isOpen}
                  placement='left'
                  onClose={onClose}
                  //finalFocusRef={btnRef}
                >
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>Category List</DrawerHeader>
                    <DrawerBody>
                    <Stack
                      bg={useColorModeValue('white', 'gray.800')}
                      p={4}
                      display={{ md: 'none' }}>
                        {category_arr.map((navItem) => (
                          <MobileNavItem key={navItem.label} {...navItem} onClose={onClose} />
                        ))}
                        {(base_hosturl == "vanillathebakery.ondemandcrm.co" || base_hosturl == "cakes.ondemandcrm.co") &&
                        <>
                        <Flex
                          //bg={useColorModeValue('white', 'gray.900')}
                          borderBottomWidth="1px"
                          //borderBottomColor={useColorModeValue('gray.200', 'gray.800')}
                          py={2}
                          as={Link}
                          //href={href ?? '#'}
                          justify={'space-between'}
                          align={'center'}
                          _hover={{
                            textDecoration: 'none',
                          }}>
                          {/* /ecommerce Remove  */}
                          <Text
                            fontWeight={600}
                            //color={useColorModeValue('gray.600', 'gray.200')}
                            color={'gray.600'}
                            onClick={()=> {navigate(`/customizecake`);}}
                            >
                            Order Custom Cake 
                          </Text>
                        </Flex>
                        </>
                        }
                      </Stack>
                      {/* <OrderedList>
                      {category_arr.map((link) => (
                        <>
                          <Link  m={2} p={4} onClick={()=> {history.push(`/ecommerce/product_list/${link.label}`);onClose();}}>
                            <Heading as='h6' size='xs'>
                              <ListItem>
                                {link.label} - Ankit
                              </ListItem>
                            </Heading>
                            
                          </Link>
                          
                        </>
                      ))}
                      </OrderedList> */}
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
            </Center>
            <Logo h="3" />
          </HStack>
          <Box>
            <MobileBottomNav isModelOnOpen={isModelOnOpen} totalQty={getTotalQuantity() || 0} />
            {/* <CurrencySelect /> */}
          </Box>
        </Flex>
        <SearchInput />
      </Box>

      <Flex flex="1" fontSize="sm" overflow="auto">
        {/* <NavCategoryMenu.Mobile /> */}
        {/* <NavCategorySubmenu.Mobile /> */}
      </Flex>
      {/* <MobileBottomNav isModelOnOpen={isModelOnOpen} totalQty={getTotalQuantity() || 0} /> */}
    </Flex>

    <Box
      display={{
        base: 'none',
        lg: 'block',
      }}
    >
      {/* bg={mode('white', 'gray.800')} change by ankit on 04/04/2023 */}
      <Box px="8" bg={mode(maincolor, '#800000')}>
        <Flex height="4.5rem" align="center" maxW="8xl" mx="auto">
          <HStack flex="11rem" spacing="32px">
            <Logo h="3" />
            {/* <Box flexShrink={0}>
              <CurrencySelect />
            </Box> */}
          </HStack>
          <Box width="full" mx="8">
            <SearchInput />
          </Box>
          {/* color="#fff" add by ankit on 04/04/2023 */}
          <HStack color={fontcolor} spacing="8" flexShrink={0}>
            {/* <NavAction.Desktop label="Wishlist" icon={RiHeartLine} /> */}
            
            <NavAction.Desktop label={cartlabel} icon={AiOutlineUser} isModelOnOpen={isModelOnOpen} ChevronDownIcon={ChevronDownIcon} navigate={navigate}/>

            {/* {localStorage.getItem("phptoken") == "1" ? <>
            <NavAction.Desktop label="Sign out" icon={AiOutlineUser} isModelOnOpen={isModelOnOpen} />
            </> : <>
            <NavAction.Desktop label="Sign in" icon={AiOutlineUser} isModelOnOpen={isModelOnOpen} />
            </> } */}
            <Box position="relative">
              <NavAction.Desktop label="Cart" icon={RiShoppingCartLine} navigate={navigate}/>
              <CartCount>{getTotalQuantity() || 0}</CartCount>
            </Box>
          </HStack>
        </Flex>
      </Box>
      <NavCategoryMenu.Desktop />
      {/* <NavCategorySubmenu.Desktop /> */}
      {/* <Box bg="blackAlpha.400" pos="fixed" zIndex="-1" inset="0" /> */}
      
    </Box>
    <Modal
      isOpen={isModelOpen}
          closeOnOverlayClick={false}
          size="2xl"
      onClose={isModelOnClose}
          
        >
          <ModalOverlay />
          <ModalContent borderRadius="2xl" mx="4">
            {/* <ModalCloseButton /> */}
            <ModalBody>
          {localStorage.getItem("phptoken") == "1" ? <Logout onClose={isModelOnClose}/> : <Login onClose={isModelOnClose} /> }
            </ModalBody>
          </ModalContent>
        </Modal>
  </>
  )}
  const MobileNavItem = ({ label, Children, href , onClose }) => {
    const { isOpen, onToggle } = useDisclosure();
    let navigate = useNavigate();
    return (
      <Stack spacing={4} onClick={Children && onToggle}>
        <Flex
          bg={useColorModeValue('white', 'gray.900')}
          borderBottomWidth="1px"
          borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
          py={2}
          as={Link}
          href={href ?? '#'}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}>
          {/* /ecommerce Remove  */}
          <Text
            fontWeight={600}
            color={useColorModeValue('gray.600', 'gray.200')}
            onClick={()=> {navigate(`/filter/${label}`);onClose();}}
            >
            {label}
          </Text>
          {Children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
  
        <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        {/* /ecommerce Remove  */}
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            align={'start'}>
            {Children &&
              Children.map((child) => (
                <Link key={child.label} py={2} onClick={()=> {navigate(`/filter/${child.label}`);onClose();}}>
                  {child.label}
                </Link>
              ))}
          </Stack>
        </Collapse>
      </Stack>
    );
  };