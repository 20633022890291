import React, { useEffect } from 'react';

function DisplayHTML({ htmlContent }) {
  useEffect(() => {
    // Select the rendered table and add CSS styles
    const table = document.querySelector('.custom-table'); // Adjust the selector to match your specific table
    if (table) {
      table.style.border = '1px solid black';
      table.style.width = '500px';
    }
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>;
}

export default DisplayHTML;