import { Box, SimpleGrid } from '@chakra-ui/react'
import * as React from 'react'
import { ProductCard } from './ProductCard'
//import { products } from './_data'

export const AppGrid = ({products,type}) => (
  <Box
    maxW="8xl"
    mx="auto"
    px={{
      base: type == "F" ? '0' : '4',
      md: type == "F" ? '0' : '8',
      lg: type == "F" ? '0' : '12',
    }}
    py={{
      base: '4',
      md: '6',
      lg: '8',
    }}
  >
    <SimpleGrid
      columns={{
        base: 2,
        sm: 2,
        md: 2,
        lg: 4,
      }}
      gap={{
        base: '4',
        lg: '6',
      }}
    >
      {products.map((product,index) => (
        <ProductCard key={index} product={product} />
      ))}
    </SimpleGrid>
  </Box>
)
