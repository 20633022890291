import { ReactNode,useState } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Text
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import Logo from './Logo';
import { useDispatch, useSelector } from 'react-redux';
import Popup from '../Basic/Popup';
import { useNavigate } from 'react-router-dom';
const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href={'#'}>
    {children}
  </Link>
);

export default function HeaderCheckout() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isPopupOpen, setPopupOpen] = useState(false); 
  const companysetting  = useSelector((state) => state.company);
  const maincolor = (companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null) ? companysetting[0].DATA.theme_maincolor : "#7c1c1c";
  const fontcolor = (companysetting.length > 0 && companysetting[0].DATA.theme_fontcolor != null) ? companysetting[0].DATA.theme_fontcolor : "#FFF";
  
  let navigate = useNavigate();
  //()=> navigate("/")
  const handleReturnToCart = () => {
    // Navigate to the home page here. You can use a library like react-router-dom for routing.
    // Example: history.push('/home');
    navigate("/cart")
  };

  const handleSubmit = () => {
    // Show the popup when the image is clicked
    setPopupOpen(true);
  };
  return (
    <>
      <Box bg={useColorModeValue(maincolor, maincolor)} px="4" py="2">
        <Flex  h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Center>
            <Text cursor="pointer" onClick={handleSubmit} fontWeight={600} fontSize={28} color={fontcolor} fontStyle={'italic'}>Back</Text></Center>
          <Logo type={1} />
          <Center>
            <Text fontWeight={600} fontSize={28} color={fontcolor} fontStyle={'italic'}> checkout</Text></Center>
            <Flex alignItems={'center'}></Flex>
        </Flex>
        <Popup  isOpen={isPopupOpen}
        onClose={() => setPopupOpen(false)}
        onReturnToCart={handleReturnToCart} />
      </Box>
    </>
  );
}
