import { Box, SimpleGrid, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import { items } from './NavItemIcons'
import { CartCount } from './CartCount'
import { NavAction } from './NavAction'
import { useDispatch, useSelector } from 'react-redux';
export const MobileBottomNav = ({isModelOnOpen,totalQty}) => {
  const companysetting  = useSelector((state) => state.company);
  const maincolor = (companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null) ? companysetting[0].DATA.theme_maincolor : "#7c1c1c";
  const fontcolor = (companysetting.length > 0 && companysetting[0].DATA.theme_fontcolor != null) ? companysetting[0].DATA.theme_fontcolor : "#FFF";
  return(
  <Box
    //bg={mode('white', 'gray.800')} comment by for background ankit on 04/04/2023
    color={fontcolor} //add  by for background ankit on 04/04/2023
    pos="relative"
    width="full"
    bottom="env(safe-area-inset-bottom)"
    //borderTopWidth="1px"
    //borderBottomWidth="1px"
    display={{
      lg: 'none',
    }}
  >
    <SimpleGrid columns={2} spacing="30px" padding="2">
      <NavAction.Mobile {...items.cart} isActive>
        <CartCount right="-2">{totalQty}</CartCount>
      </NavAction.Mobile>
      {/* <NavAction.Mobile {...items.search} /> */}
      <NavAction.Mobile {...items.user} isModelOnOpen={isModelOnOpen} />
      {/* <NavAction.Mobile {...items.wishlist} /> */}
    </SimpleGrid>
  </Box>
)}
