import { createSlice } from '@reduxjs/toolkit';
const homecatSlice = createSlice({
    name: 'homecat',
    initialState: {
        category1: [],
        category2: [],
        category3: []
    },
    reducers: {
        addHomeCategory1: (state, action) => {
            state.category1 = action.payload;
        },
        addHomeCategory2: (state, action) => {
            state.category2 = action.payload;
        },
        addHomeCategory3: (state, action) => {
            state.category3 = action.payload;
        },
    },
});

export const homecatReducer = homecatSlice.reducer;
export const {
    addHomeCategory1,
    addHomeCategory2,
    addHomeCategory3
} = homecatSlice.actions;