import { FormControl, FormLabel, HStack, useRadioGroup,Grid,Text,Select } from '@chakra-ui/react'
import React from 'react'
import { SizePickerButton } from './SizePickerButton'

export const AttributePicker = (props) => {
  const { options,register,handleChange, rootProps, hideLabel, label, ...rest } = props;
  const { getRadioProps, getRootProps, value } = useRadioGroup(rest)
  const selectedOption = Object.values(options).find((option) => option.ID == value);
  //console.log("OPTTT");console.log(options);
  return (
    <Select 
        pointerEvents={options.code_gen == 1 ? "none" : ""} 
        size='sm'
        placeholder={options.Is_Required > 0 ? `Select option` : ''}
        defaultValue={options.ValueID}
        {...register(`Attr[][${options.ID}]`)}  onChange={handleChange}>
        {Object.values(options.Masters).map((option_sel) => (
            <option value={option_sel.ID} >{option_sel.N}</option>
        ))}
    </Select>
  )
}
