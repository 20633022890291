import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {
    Container,Box,Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent,
    DrawerCloseButton, useDisclosure,Button,Avatar, Spacer, Flex  ,Alert,AlertIcon,
} from '@chakra-ui/react';
import AttributeMaster from './AttributeMaster';
import CustomFieldMaster from './CustomFieldMaster';
import http from "../http-common";
import swal from 'sweetalert';
import DynamicButton from './DynamicButton';
import { useDispatch,useSelector } from 'react-redux';
import {addToProducts,filterProductCategory} from '../redux/slices/productSlice';
import { useForm } from "react-hook-form";
import { AppGrid } from './ProductGrid/AppGrid';
import { Lines } from 'react-preloaders';
import { Header } from '../header/Header';
import Footer from '../Footer/Footer';

export default function ProductList() {
    document.title = 'Home/Product List';
    const products_arr = useSelector((state) => state.products.products);
    const setproducts_arr = useSelector((state) => state.products.setProducts);
    const companysetting  = useSelector((state) => state.company);
    const { register, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm();
    const [products, setProducts] = useState([]);
    const [loading,setLoading] = useState(true);
    const [selproducts, setSelProducts] = useState([]);
    const [searchattributes, setSearchAttributes] = useState([]);
    const [searchcustoms, setSearchCustoms] = useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const baseurl = useSelector(state => state.baseurl.baseURL);; //window.location.origin; //window.location.origin; //"https://gadgets.ondemandcrm.co";
    let { categoryname } = useParams();
    const dispatch = useDispatch();
    const IMAGE = `${baseurl}/public/images/MedBot/no_product_1.png`;
    
    useEffect(()=>{
        window.scrollTo(0, 0);
    },['']);


    useEffect(() => {
        //console.log(categoryname);
        setLoading(true);
        const spinner = document.getElementById("spinner");
        if(spinner){
            spinner.style.display = "flex";
        }
        async function getProductCategoryList() {
            let url = '/ecommerce_api/getCategorywiseProductList/';
            const res = await http.post(url, {
                AUTHORIZEKEY: "",
                category_name: categoryname.replaceAll("-"," "),
            });
            if (res.data[0].STATUS == "SUCCESS") {
                dispatch(addToProducts(res.data[0].DATA.products));
                if(spinner){
                    spinner.style.display = "none";
                }
                setLoading(false);
            } else {
              dispatch(addToProducts([]));
              if(spinner){
                    spinner.style.display = "none";
                }
                setLoading(false);
            }
        }
        getProductCategoryList();
    }, [categoryname]);

    useEffect(() => {
        setProducts(products_arr);
        setSelProducts(setproducts_arr);
        setIsLoaded(true);
    }, [setproducts_arr]);

    const onSubmit = (data) => {
        http.post(`/ecommerce_api/getProductSearchList/`, {
            moredata: data,
            category_name: categoryname,
        }).then(res => {
            if (res.data[0].STATUS == "SUCCESS") {
                setSelProducts(res.data[0].DATA.products);
                onClose();
            } else {
                setSelProducts([]);
                onClose();
            }
        }).catch(err => {
            reset({ ...data })
            swal("Something went wrong", "error");
        });
    }
    const getAttributeMaster = async () => {
        try {
            const res = await http.post('/ecommerce_api/getAttributeMaster/', {
                AUTHORIZEKEY: "",
            });
            if (res.data[0].STATUS == "SUCCESS") {
                setSearchAttributes(res.data[0].DATA.attribute_arr);
            } else {
                setSearchAttributes([]);
            }
        } catch (error) {
            console.error(error); // You might send an exception to your error tracker like AppSignal
            return error;
        }
    }
    useEffect(() => {
        getAttributeMaster();
    }, []);
    const getCustomFieldMaster = async () => {
        try {
            const res = await http.post('/ecommerce_api/getProductCustomFieldMaster/', {
                AUTHORIZEKEY: "",
            });
            if (res.data[0].STATUS == "SUCCESS") {
                setSearchCustoms(res.data[0].DATA.customs);
                onClose();
            } else {
                setSearchCustoms([]);
            }
        } catch (error) {
            console.error(error);
            return error;
        }
    }
    useEffect(() => {
        getCustomFieldMaster();
    }, []);

    const handleResetFilter = (e) => {
        setSelProducts(setproducts_arr);
        reset({});
        onClose();
    }
    return (
        !loading && (
        <>
           <Header/>
           <Drawer
                isOpen={isOpen}
                placement='top'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Filter Criteria</DrawerHeader>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DrawerBody>
                            {(companysetting.length > 0 && companysetting[0].DATA.enable_attribute == "Y") && 
                                <AttributeMaster register={register} attributes={searchattributes} />
                            }
                            {(companysetting.length > 0 && companysetting[0].DATA.enable_customfield == "Y") && 
                                <CustomFieldMaster setValue={setValue} regi={register} customs={searchcustoms} />
                            }
                        </DrawerBody>
                        <DrawerFooter>
                            <Button variant='outline' size='sm' mr={3} onClick={handleResetFilter}>
                                Reset
                            </Button>
                            <Button type="submit" colorScheme='green' size='sm'>Search</Button>
                        </DrawerFooter>
                    </form>
                </DrawerContent>
            </Drawer>
            <DynamicButton />
            <Box as={Container} maxW="7xl" mt={2} p={4} minH={'750px'}>
                <Flex pl={12} pr={12}>
                    <Box  fontSize='2xl' >
                        {categoryname.replaceAll('-',' ')}
                    </Box>
                    <Spacer />
                    {(companysetting.length > 0 && (companysetting[0].DATA.enable_attribute == "Y" || companysetting[0].DATA.enable_customfield == "Y")) && 
                    <Box p='1'>
                        <Avatar onClick={onOpen} cursor="pointer" float="right" name='Filter' src={`${baseurl}/public/images/MedBot/filter.png.svg`} />
                    </Box>
                    }
                </Flex>
                {selproducts.length > 0 ?
                   <AppGrid products={selproducts} />
                 : <>
                        <Alert status='warning'>
                            <AlertIcon />
                            Product Not Available
                        </Alert>
                    </>
                }
            </Box>
           <Footer/>
        </>
        )
    )
}
