import {
  Button,
  chakra,
  useColorModeValue,
  useRadio,
  useTheme,
  VisuallyHidden,
} from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import * as React from 'react'

export const SizePickerButton = (props) => {
  const { value, label } = props
  const { getInputProps, htmlProps, getCheckboxProps, getLabelProps } = useRadio(props)
  const theme = useTheme()
  return (
    <chakra.label {...htmlProps}>
      <chakra.input {...getInputProps()} />
      <Button
        as="span"
        px="0"
        p={2}
        minW={'120px'}
        cursor="pointer"
        variant="outline"
        colorScheme="green"
        color={useColorModeValue('gray.600', 'gray.400')}
        borderRadius="base"
        borderColor={useColorModeValue('gray.200', 'gray.600')}
        _checked={{
          color: useColorModeValue('green.800', 'green.200'),
          bg: useColorModeValue('green.80', transparentize('green.200', 0.12)(theme)),
          borderColor: useColorModeValue('green.800', 'green.200'),
          borderWidth: '2px',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        _focusVisible={{
          boxShadow: 'outline',
        }}
        {...getCheckboxProps()}
      >
        {value}
      </Button>
      <VisuallyHidden {...getLabelProps()}>{label} selected</VisuallyHidden>
    </chakra.label>
  )
}
