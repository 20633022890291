import { Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RiSearchLine } from 'react-icons/ri';
import { filterCategory, addToCategory } from '../redux/slices/categorySlice';
import { filterProduct, addToProducts } from '../redux/slices/productSlice';
import { useDispatch, useSelector } from 'react-redux';

export const SearchInput = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [timer, setTimer] = useState(null);
  const LINK_TYPE = process.env.REACT_APP_LINK_TYPE;
  //console.log(LINK_TYPE);
  const category_arr = useSelector(state => state.category.category);
  const handleChangeInput = e => {
    const link_arr = window.location.href;
    //console.log(link_arr);
    const answer_array = link_arr.split('/');
    //console.log(answer_array);
    if (LINK_TYPE != '1') {
      //console.log("POP");
      answer_array.pop();
    }
    //answer_array[4] == "searchproduct"
    {
      /* /ecommerce Remove  */
    }
    //console.log(answer_array);
    if (answer_array.length == (LINK_TYPE == 1 ? 4 : 3)) {
      const product_name = e.target.value;
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          navigate(`/searchproduct/${product_name}`);
        }, 1500)
      );
    } else {
      if (answer_array[LINK_TYPE == 1 ? 4 : 3] == 'category') {
        dispatch(filterCategory(e.target.value));
      } else if (
        answer_array[LINK_TYPE == 1 ? 4 : 3] == 'searchproduct' ||
        answer_array[LINK_TYPE == 1 ? 4 : 3] == 'cart'
      ) {
        const product_name = e.target.value;
        if (timer) {
          clearTimeout(timer);
          setTimer(null);
        }
        setTimer(
          setTimeout(() => {
            if (product_name == '') {
              if (answer_array[LINK_TYPE == 1 ? 4 : 3] != 'cart') {
                navigate('/');
              }
            } else {
              navigate(`/searchproduct/${product_name}`);
            }
          }, 1500)
        );
      } else if (answer_array[LINK_TYPE == 1 ? 4 : 3] == 'filter') {
        dispatch(filterProduct(e.target.value));
      } else {
        dispatch(addToCategory(category_arr));
      }
    }
  };
  return (
    <InputGroup bg="#fff" borderRadius="10px">
      <InputLeftElement borderRadius="10px">
        <Icon as={RiSearchLine} color="gray.500" fontSize="lg" />
      </InputLeftElement>
      <Input
        borderRadius="10px"
        focusBorderColor="blue.500"
        width="full"
        fontSize="sm"
        variant="filled"
        type="text"
        placeholder="What are you looking for?"
        autoComplete="off"
        onChange={handleChangeInput}
      />
    </InputGroup>
  );
};
