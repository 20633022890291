import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CompanyDataService from "../services/company.service";

//const initialState = [];
export const retrieveCompany = createAsyncThunk(
  "/expo_access_api/companydetails",
  async () => {
    const res = await CompanyDataService.getAll();
    return res.data;
  }
);
const companySlice = createSlice({
  name: "company",
  initialState: {
    company:[],
    logo:""
  },
  reducers:{
    changeCompanyLabel: (state, action) => {
      state.logo = action.payload;
    },
  },
  extraReducers: {
    [retrieveCompany.fulfilled]: (state, action) => {
      return [...action.payload];
    },
  },
});
const { reducer } = companySlice;
export default reducer;
export const {
  changeCompanyLabel,
} = companySlice.actions;