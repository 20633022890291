// src/redux/categorySlice.js
import { createSlice } from '@reduxjs/toolkit';
const timeslotSlice = createSlice({
    name: 'timeslot',
    initialState: {
        timeslot: [],
    },
    reducers: {
        addTimeSlot: (state, action) => {
            state.timeslot = action.payload ;
        },
    },
});

export const timeslotReducer = timeslotSlice.reducer;
export const {
    addTimeSlot,
} = timeslotSlice.actions;