import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  baseURL: '',
  basehostname: '',
};

const baseurlSlice = createSlice({
  name: 'baseurl',
  initialState,
  reducers: {
    setBaseURL: (state, action) => {
      state.baseURL = action.payload;
    },
    setBasehostname: (state, action) => {
      state.basehostname = action.payload;
    },
  },
});

export const { setBaseURL, setBasehostname } = baseurlSlice.actions;
export const baseurlReducer = baseurlSlice.reducer;
