import { Select, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {filterProductByPrice} from '../../redux/slices/productSlice';

const sortByOptions = {
  defaultValue: 'best-seller',
  options: [
    {
      label: 'Select',
      value: '',
    },
    {
      label: 'Price: Low to High',
      value: '1',
    },
    {
      label: 'Price: High to Low',
      value: '2',
    },
  ],
}

export const SortbySelect = (props) => {
  const dispatch = useDispatch();
  const changeSortbyPrice = (e) =>{
    if(e.target.value > 0){
      dispatch(filterProductByPrice(e.target.value));
    }
  }
  return(
      <Select
      size="sm"
      aria-label="Sort by"
      defaultValue={sortByOptions.defaultValue}
      focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
      rounded="md"
      onChange={changeSortbyPrice}
      {...props}
    >
      {sortByOptions.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}
