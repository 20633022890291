import { createSlice } from '@reduxjs/toolkit';
const brandSlice = createSlice({
    name: 'brand',
    initialState: {
        brands: [],
    },
    reducers: {
        addBrandMaster: (state, action) => {
            state.brands = action.payload;
        },
    },
});

export const brandReducer = brandSlice.reducer;
export const {
    addBrandMaster,
} = brandSlice.actions;