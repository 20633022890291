import { Box, Center,Button, Flex, HStack, Icon, Text, useColorModeValue as mode,Menu,MenuButton,MenuItem,MenuList } from '@chakra-ui/react'
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChevronDownIcon,
} from '@chakra-ui/icons';
const MobileNavAction = (props) => {
  const { label, icon, isActive, href, children,isModelOnOpen } = props;
  let navigate = useNavigate();
  return (
    <Center
      as="a"
      href={href}
      height="56px"
      rounded="4"
      aria-current={isActive ? 'page' : undefined}
      _activeLink={{
        //color: mode('blue.500', 'blue.300'), comment by for background ankit on 04/04/2023
      }}
      _hover={{
        bg:mode('blue.500', 'blue.300') //bg: mode('gray.100', 'gray.700'),comment by for background ankit on 04/04/2023
      }}
    >
      {(label == "Sign in" || label == "Sign out") ? ( <>
        {(localStorage.getItem("phptoken") == "1") ? 
        <Menu ><MenuButton fontSize="sm" fontWeight="semibold">
            My Account <ChevronDownIcon/>
          </MenuButton>
          <MenuList color={'black'}>
            <MenuItem onClick={()=>{navigate("/profile")}}><Text fontSize="sm" fontWeight="semibold">My Profile</Text></MenuItem>
            <MenuItem onClick={isModelOnOpen}>
              <HStack spacing="2" as="button" >
                <Text  fontSize="sm" fontWeight="bold">
                  Sign out
                </Text>
                {/* <Icon as={icon} /> */}
              </HStack>
            </MenuItem>
          </MenuList>
          </Menu>
          :
          <Flex position="relative" direction="column" align="center" as="button" onClick={isModelOnOpen}>
            <Box fontSize="xl" as={icon} />
            <Text fontSize="sm" fontWeight="medium">
              {localStorage.getItem("phptoken") == "1" ? 'Sign out' : 'Sign in'}
            </Text>
            {children}
          </Flex> 
         }
      </> )
     : label == "Cart" ? ( <> {/* /ecommerce Remove  */}
      <Flex position="relative" direction="column" align="center" as="button" onClick={()=> navigate("/cart")}>
        <Box fontSize="xl" as={icon} />
        <Text fontSize="sm" fontWeight="medium">
          {label}
        </Text>
        {children}
      </Flex>
     </> ) : (
      <>
        <Flex position="relative" direction="column" align="center" as="button">
          <Box fontSize="xl" as={icon} />
          <Text fontSize="sm" fontWeight="medium">
            {label}
          </Text>
          {children}
        </Flex>
      </>)}
      
    </Center>
  )
}
const DesktopNavAction = (props) => {
  const { label, icon, href = '#',isModelOnOpen,navigate,ChevronDownIcon, } = props;
  {/* /ecommerce Remove  */}
  
  return (
    <>
    {(label == "Sign in" || label == "Sign out") ? (
      <>
      {label == "Sign in" ? <HStack spacing="2" as="button" onClick={isModelOnOpen}>
                <Text fontSize="sm" fontWeight="semibold">
                  {label}
                </Text>
                <Icon as={icon} />
              </HStack> : <Menu >
          <MenuButton fontSize="sm" fontWeight="semibold">
            My Account <ChevronDownIcon />
          </MenuButton>
          <MenuList color={'black'}>
            <MenuItem onClick={()=>{navigate("/profile")}}><Text fontSize="sm" fontWeight="semibold">My Profile</Text></MenuItem>
            <MenuItem onClick={isModelOnOpen}>
              <HStack spacing="2" as="button" >
                <Text  fontSize="sm" fontWeight="bold">
                  {label}
                </Text>
                {/* <Icon as={icon} /> */}
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>}
    </>
    )
     : label == "Cart" ? ( 
    <HStack spacing="2" as="button" onClick={()=> navigate("/cart")}>
      <Text fontSize="sm" fontWeight="semibold">
        {label}
      </Text>
      <Icon as={icon} />
    </HStack>
    ) : (
    <HStack spacing="2" as="button" >
      <Text fontSize="sm" fontWeight="semibold">
        {label}
      </Text>
      <Icon as={icon} />
    </HStack>)}
    </>
  )
}

export const NavAction = {
  Mobile: MobileNavAction,
  Desktop: DesktopNavAction,
}
