import React,{ useState,useEffect } from 'react';
import Footer from '../Footer/Footer';
import { Header } from '../header/Header';
import DynamicButton from './DynamicButton';
import { useDispatch,useSelector } from 'react-redux';
import {
    Container,Box,Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent,
    DrawerCloseButton, useDisclosure,Button,Avatar, Spacer, Flex  ,Alert,AlertIcon,
} from '@chakra-ui/react';
import { AppGrid } from './ProductGrid/AppGrid';
import { useParams } from 'react-router-dom';
import http from "../http-common";

export const ProductSearch = () => {
    const { productname } = useParams();
    const [selproducts, setSelProducts] = useState([]);
    useEffect(()=>{
        //console.log(productname);
        const spinner = document.getElementById("spinner");
        if(spinner){
            spinner.style.display = "flex";
        }
        async function getProductSearchList() {
            let url = '/ecommerce_api/getProductSearchListByProductName/';
            const res = await http.post(url, {
                AUTHORIZEKEY: "",
                product_name: productname,
            });
            if (res.data[0].STATUS == "SUCCESS") {
                setSelProducts(res.data[0].DATA.products);
                if(spinner){
                    spinner.style.display = "none";
                }
            }else {
              setSelProducts([]);
              if(spinner){
                    spinner.style.display = "none";
                }
            }
        }
        getProductSearchList();
    },[productname]);
    return (
        <>
            <Header/>
            <DynamicButton/>
            <Box as={Container} maxW="7xl" mt={2} p={4} minH={'750px'}>
                {selproducts.length > 0 ?
                   <AppGrid products={selproducts} />
                 : <>
                        <Alert status='warning'>
                            <AlertIcon />
                            Product Not Available
                        </Alert>
                    </>
                }
            </Box>
            <Footer/>
        </>
    )
}
