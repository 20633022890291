import React from 'react'
import {
    Box,FormControl,FormLabel,SimpleGrid,Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
  } from '@chakra-ui/react';
import CustomField from './CustomField';
export default function CustomFieldMaster({customs,regi,setValue}) {
    const customdata = Object.values(customs);
    let j =0;
  return (
    <>
        {customdata && customdata.map((item,i) => {
            return(
                <Box borderWidth='1px' borderRadius='lg' mt={1}>
                    <Accordion allowToggle key={item.ID}>
                        <AccordionItem>
                            <h2>
                            <AccordionButton  _expanded={{ bg: 'gray.300'}}>
                                <Box flex='1' textAlign='left'>
                                {item.Name}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} >
                                <SimpleGrid  minChildWidth='200px' spacing='20px' p={0}>
                                    {Object.values(item.Fields).map((field,index1) => {
                                        ++j;
                                        return(
                                            <>
                                                <FormControl key={j}>
                                                    <FormLabel>{field.Name}</FormLabel>
                                                    <CustomField checki={j} setValue={setValue} groupname={field.ID} field_id={field.ID} type={field.Type} options={field.Options} register={regi} preFillMulti = {field.Type == 12 ? field.PreFilled : "[]"} PreFilled={field.PreFilled} />
                                                </FormControl>
                                            </>
                                        )
                                        
                                    })}
                                </SimpleGrid>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </Box>
            )
        })}
    </>
  )
}
