import React,{useEffect} from 'react';
import {
    Flex,
    Stack,
    Heading,
    Text,
    Input,
    Button,
    Icon,
    useColorModeValue,
    createIcon,Box,Container,Image,HStack,Link,useColorModeValue as mode,
  } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { changeCategoryLabel } from '../../redux/slices/categorySlice';
import Footer from '../../Footer/Footer';
import { Header } from '../../header/Header';
import { removeAllItem,changeAuthLabel,removeAllDummyCart,changePincode,changeDeliveyStatus } from '../../redux/slices/cartSlice';
import { useNavigate } from 'react-router-dom';
const failImage = '../image/paymentfail.png';

function PaymentCancel() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(changeCategoryLabel(''));
        const spinner = document.getElementById('spinner');
        if (spinner) {
          setTimeout(() => {
            spinner.style.display = 'none';
          }, 1500);
        }
      }, ['']);
      useEffect(()=>{
        dispatch(removeAllItem());
        dispatch(removeAllDummyCart());
      },['']);
  return (
    <>
     <Header />
      <Box as={Container} maxW="7xl" mt={2} p={4} minH={'750px'}>
      <Flex
      minH={'70vh'}
      align={'center'}
      justify={'center'}
      py={12}
      //bg={useColorModeValue('gray.50', 'gray.800')}
      >
      <Stack
        boxShadow={'2xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        p={10}
        spacing={8}
        align={'center'}>
        <Image src={failImage} alt="Success" w={52} h="auto" />
        <Stack align={'center'} spacing={2}>
          <Heading
            textTransform={'uppercase'}
            fontSize={'3xl'}
            color={useColorModeValue('red.800', 'red.200')}>
                Payment Cancelled
          </Heading>
          <Text fontSize={'lg'} color={'gray.500'}>
            Your payment was not successful.Please try again later or contact support for assistance.
          </Text>
          <HStack mt="6" fontWeight="semibold">
          <Link color={mode('green.800', 'green.200')} onClick={()=> navigate("/filter/ALL")}>Continue shopping</Link>
        </HStack>
        </Stack>
        
      </Stack>
    </Flex>
    </Box>
    <Footer />
    </>
  );
}

export default PaymentCancel;