import { AiOutlineUser } from 'react-icons/ai'
import { RiHeartLine, RiSearchLine, RiShoppingCartLine } from 'react-icons/ri'
const Data_Val = localStorage.getItem("phptoken") == "1" ? 'Sign out' : 'Sign in';
export const items = {
  wishlist: {
    label: 'Wishlist',
    icon: RiHeartLine,
    href: '#',
  },
  user: {
    label: Data_Val,
    icon: AiOutlineUser,
    href: '#',
  },
  cart: {
    label: 'Cart',
    icon: RiShoppingCartLine,
    href: '#',
  },
  search: {
    label: 'Search',
    icon: RiSearchLine,
    href: '#',
  },
}
