import React, {useState}  from "react";
import Datetime from 'react-datetime';
import { MultiSelect } from "react-multi-select-component";
import { Radio, RadioGroup,Select,Input,Textarea } from '@chakra-ui/react'

function FieldComponent({type,register,options,groupname,field_id,PreFilled,setValue,preFillMulti}){
    let newArray = [];
    if(preFillMulti != "[]"){
        var match_pre = preFillMulti. split(',');
        if(match_pre && match_pre.length > 0){
            newArray = match_pre.map((it) => {
                return {
                    'label' : it,
                    'value' : it
                }
            });
        }
    }
    const [selected, setSelected] = useState(newArray);
    let button;
    if(type == 1 || type == 7 || type == 8 || type == 4 || type == 3){
        setValue(`customs_${field_id}`, PreFilled);
        button = <Input type='text' {...register(`customs_${field_id}`)} />
    }else if(type == 6){
        setValue(`customs_${field_id}`, PreFilled);
        button = <Input type="email"  {...register(`customs_${field_id}`)} />
    }else if(type == 2){
        setValue(`customs_${field_id}`, PreFilled > 0 ? PreFilled : 0);
        button = <Input type="text" {...register(`customs_${field_id}`)} />
    }else if(type == 5){
        setValue(`customs_${field_id}`, PreFilled);
        button = <Input type="date"  {...register(`customs_${field_id}`)} />
    }else if(type == 9){
        setValue(`customs_${field_id}`, PreFilled);
        button = <Textarea value={PreFilled} placeholder="Leave a comment here" {...register(`customs_${field_id}`)} />
    }else if(type == 10){
        var array_check = PreFilled.split(',');
        setValue(`customs_${field_id}`, array_check);
        button = (
        <div>
            {options.map((opt,index) => (
                <div className="form-check form-check-inline" key={index}>
                    <input className="form-check-input" type="checkbox" value={opt.ID} id="flexCheckDisabled" {...register(`customs_${field_id}`)} />
                    <label className="form-check-label">
                        {opt.Value}
                    </label>
                </div>
            ))}
        </div>
        )

    }else if(type == 11){
        setValue(`customs_${field_id}`, PreFilled);
        button = (
            <>
                <Select placeholder='Select option' {...register(`customs_${field_id}`)} size='sm'>
                    {options.map((opt,index) => (
                        <option key={index} value={opt.ID}>{opt.Value}</option>
                    ))}
                </Select>
            </>
        )
    }else if(type == 12){
        let arr1 = options.map(item => {
        return {
            'label' : item.Value,
            'value' : item.ID
        }
        });
        button = (
        <div>
            <Input type="hidden" value={JSON.stringify(selected)}   {...register(`customs_${field_id}`)} />
            <MultiSelect
            options={arr1}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
            />
        </div>
      )
    }else if(type == 13){
        setValue(`customs_${field_id}`, PreFilled);
        button = (
        <div>
            {options.map((opt,index) => (
                <>
                <div className="form-check form-check-inline" key={index}>
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="exampleRadios"  
                        value={opt.ID} 
                        {...register(`customs_${field_id}`)} 
                    />
                    <label className="form-check-label">
                    {opt.Value}
                    </label>
                </div>
                </>
            ))}
        </div>
        )
    }else if(type == 14){
        setValue(`customs_${field_id}`, PreFilled);
        button = <Input type="time"  {...register(`customs_${field_id}`)} />
    }else if(type == 15){
        button = <Input type="datetime-local" className="form-control"  {...register(`customs_${field_id}`)} />
    }
    return(
        <>
           {button} 
        </>
    )
}

export default FieldComponent;