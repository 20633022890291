import {
  Box,
  Flex,
  Heading,
  HStack,
  Link,
  Stack,
  useColorModeValue as mode
} from '@chakra-ui/react'
import { CartItem } from './CartItem'
import { CartOrderSummary } from './CartOrderSummary'
import { cartData } from './_data'
import { useSelector } from 'react-redux';

export const App = ({cartArray,navigate,totalQty}) => {
  const companysetting  = useSelector((state) => state.company);
  const maincolor = (companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null) ? companysetting[0].DATA.theme_maincolor : "#7c1c1c";

  return (
  <Box
    maxW={{
      base: '3xl',
      lg: '7xl',
    }}
    mx="auto"
    px={{
      base: '4',
      md: '8',
      lg: '12',
    }}
    py={{
      base: '6',
      md: '8',
      lg: '12',
    }}
  >
    <Stack
      direction={{
        base: 'column',
        lg: 'row',
      }}
      align={{
        lg: 'flex-start',
      }}
      spacing={{
        base: '8',
        md: '16',
      }}
    >
      <Stack
        spacing={{
          base: '8',
          md: '10',
        }}
        flex="2"
      >
        <Heading fontSize="2xl" fontWeight="extrabold">
          {(companysetting.length > 0 && companysetting[0].DATA.label_title_cart != null) ? companysetting[0].DATA.label_title_cart : "Shopping Cart"} ({totalQty} items)
        </Heading>

        <Stack spacing="6">
          {cartArray.map((item) => (
            <CartItem key={item.id} {...item} />
          ))}
        </Stack>
      </Stack>

      <Flex direction="column" align="center" flex="1" >
        <CartOrderSummary cart_array={cartArray} />
        <HStack mt="6" fontWeight="semibold">
          <p>or</p>
          <Link color={maincolor} onClick={()=> navigate("/filter/ALL")}>Continue shopping</Link>
        </HStack>
      </Flex>
    </Stack>
  </Box>
)}
