import {
  Box,
  Flex,
  Heading,
  HStack,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import React,{ useState } from 'react'
import { CartItem } from './CartItem'
import { CartOrderSummary } from './CartOrderSummary'
import { useSelector } from 'react-redux';
import http from "../../http-common";
import { useEffect } from 'react';
import { ClockLoader } from 'react-spinners';

export const AppCart = ({cartArray,navigate,totalQty}) => {
  const companysetting  = useSelector((state) => state.company);
  const maincolor = (companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null) ? companysetting[0].DATA.theme_maincolor : "#7c1c1c";
  const client_coordinates  = useSelector((state) => state.coordinate);
  const [cartar,setCartArr] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [trans_type,setTransactionType] = React.useState();
  const [pickup_status,setPickupStatus] = React.useState(0); 
  const [pickup_statu_msg,setPickupStatusMsg] = React.useState("");
  const [shipping_charge,setShippingCharge] = React.useState(0);
  const [disabledisplaytext, setDisableDisplayText] = React.useState(true);
  const [shipp_orderid, setShippOrderId] = React.useState("");
  const [shipp_ordermessage, setShippOrderMessage] = React.useState("");
  const calculateTax = async() =>{
    const { lat,lng } = client_coordinates.coordinate;
    setIsLoading(true);
    //#========= Temparary add by on 19/09/2023
    //setPickupStatus(0);
    let url = '/ecommerce_api/calculateTaxForCart/';
    const res = await http.post(url, {
        AUTHORIZEKEY: "",
        contact_id: localStorage.getItem("contact_id"),
        cart:cartArray,
        client_latitude:lat, //client_coordinates.coordinate.lat,
        client_longitude:lng //client_coordinates.coordinate.lng,
    }).then((res) => {
      if (res.data[0].STATUS == "SUCCESS") {
        setCartArr(res.data[0].DATA.cart_array);
        setIsLoading(false);
        //#========= Temparary Comment on 19/09/2023
        calculateShippingCharges();
        //#========= Temparary add by on 19/09/2023
        //setPickupStatus(1);
        //#============= end here
      } else {
        setCartArr(cartArray);
        setIsLoading(false);  
      }
    });
  }

  const calculateShippingCharges = async() =>{
    setDisableDisplayText(false);
    const { lat,lng } = client_coordinates.coordinate;
    //console.log("checkCOardinates");
    //console.log(client_coordinates);
    setIsLoading(true);
    let url = '/ecommerce_api/calculateShippingChargeUpdated/';
    const res = await http.post(url, {
        AUTHORIZEKEY: "",
        contact_id: localStorage.getItem("contact_id"),
        cart:cartArray,
        client_latitude:lat, //client_coordinates.coordinate.lat,
        client_longitude:lng //client_coordinates.coordinate.lng,
    }).then((res) => {
      setIsLoading(false);
      if (res.data[0].STATUS == "SUCCESS") {
        setPickupStatus(res.data[0].DATA.pickup_available);
        setShippingCharge(res.data[0].DATA.shipping_charge);
        setDisableDisplayText(true);
        setPickupStatusMsg(res.data[0].DATA.shipping_msg);
        setShippOrderId(res.data[0].DATA.order_id);
        setShippOrderMessage(res.data[0].DATA.order_detail);
      } else {
        setPickupStatus(0);
        setShippingCharge(0);
        setDisableDisplayText(true);
        setPickupStatusMsg(res.data[0].DATA.shipping_msg);
        setShippOrderId("");
        setShippOrderMessage("");
      }
    });
  }
  useEffect(()=>{
    async function checkInvoiceOrElse() {
      let url = '/ecommerce_api/checkInvoiceOrElse/';
      const res = await http.post(url, {
          AUTHORIZEKEY: "",
      }).then((res) => {
        if (res.data[0].STATUS == "SUCCESS") {
          if(res.data[0].DATA.transaction_type == 22){
            calculateTax();
          }else{
            setCartArr(cartArray);
            if(cartArray.length > 0){
              //#========= Temparary Comment on 19/09/2023
              calculateShippingCharges();
            }
          }
          setTransactionType(res.data[0].DATA.transaction_type);
        }
      });
    }
    checkInvoiceOrElse();

    /*const transaction_type = (companysetting.length > 0 ? companysetting[0].DATA.theme_transaction_type : 0);
    console.log("Transaction Type");console.log(transaction_type);
    if(transaction_type == 22){
      calculateTax();
    }else{
      setCartArr(cartArray);
      if(cartArray.length > 0){
        calculateShippingCharges();
      }
    }
    setTransactionType(transaction_type);*/
  },[cartArray])

  return (
  <Box
    maxW={{
      base: '3xl',
      lg: '7xl',
    }}
    mx="auto"
    px={{
      base: '4',
      md: '8',
      lg: '12',
    }}
    py={{
      base: '6',
      md: '8',
      lg: '12',
    }}
  >
    <Stack
      direction={{
        base: 'column',
        lg: 'row',
      }}
      align={{
        lg: 'flex-start',
      }}
      spacing={{
        base: '8',
        md: '16',
      }}
    >
      <Stack
        spacing={{
          base: '8',
          md: '10',
        }}
        flex="2"
      >
        <Heading fontSize="2xl" fontWeight="extrabold">
          {(companysetting.length > 0 && companysetting[0].DATA.label_title_cart != null) ? companysetting[0].DATA.label_title_cart : "Shopping Cart"} ({totalQty} items)
        </Heading>
        {isLoading ? (
          <div className="spinner-container-clock">
            <ClockLoader  color="#000" />
          </div>
        ) : (
          <Stack spacing="6">
            <>
            <Flex
              width="full"
              display={{
                base: 'none',
                md: 'flex',
              }}
              align="center"
            >
              {/*---2,3----*/}
              <Text flex={trans_type == 22 ? "2" : "1"} fontWeight="bold">Image</Text>
              <Text flex={trans_type == 22 ? "3" : "1"} fontWeight="bold">Product</Text>
              <Text flex="1" fontWeight="bold">Qty</Text>
              <Text flex="1" fontWeight="bold">Rate</Text>
              {trans_type == 22 && <>
              <Text flex="1" fontWeight="bold">Tax %</Text>
              <Text flex="1" fontWeight="bold">Tax </Text>
              <Text flex="1" fontWeight="bold">Total</Text></>
              }
            </Flex>
            
            {cartar.map((item) => (
              <CartItem key={item.id} {...item} trans_type={trans_type} />
            ))}
            </>
          </Stack>
        )}
      </Stack>
      <Flex direction="column" align="center" flex="1">
        <CartOrderSummary cart_array={cartar} trans_type={trans_type} pickup_status={pickup_status} shipping_charge={shipping_charge} disabledisplaytext={disabledisplaytext}  pickup_statu_msg={pickup_statu_msg} shipp_orderid={shipp_orderid} shipp_ordermessage={shipp_ordermessage}  />
      </Flex>
    </Stack>
  </Box>
)}
