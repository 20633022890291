import { CloseButton,Box,ButtonGroup,Text, Center,Flex, Link, Select, useColorModeValue, IconButton, Button } from '@chakra-ui/react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom';
import { PriceTag } from './PriceTag'
import { CartProductMeta } from './CartProductMeta'
import { QuantityPickerCart } from '../ProductDetail/QuantityPickerCart';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { incrementQuantity, decrementQuantity, removeItem} from '../../redux/slices/cartSlice';
import { useDispatch } from 'react-redux'

export const CartItem = (props) => {
  const dispatch = useDispatch();
  const {
    id,
    isGiftWrapping,
    title,
    description,
    quantity,
    image,
    currency,
    price,
    Tax,
    Shipping,
    TotalTax,
    Total,
    onChangeQuantity,
    onClickDelete,
    trans_type,
    newlengthid,
    matchedValues,
    master_key
  } = props;
  const currency1 = "INR";
  const currency2 = "";
  let navigate = useNavigate();
  return (
    <>
    <Flex
      direction={{
        base: 'column',
        md: 'row',
      }}
      justify="space-between"
      align="center"
    >
      <CartProductMeta
        name={title}
        description={description}
        image={image}
        isGiftWrapping={isGiftWrapping}
        existingAttributeValue={matchedValues}
      />

      {/*  DeskTop  */}
      <Flex
        width="full"
        display={{
          base: 'none',
          md: 'flex',
        }}
        align="center"
      >
        {/* <Text flex="2" fontWeight="bold">
          <CartProductMeta
          name={title}
          description={description}
          image={image}
          isGiftWrapping={isGiftWrapping}
          existingAttributeValue={matchedValues}
        />
        </Text> */}
        <Text flex="1" fontWeight="bold">
          <ButtonGroup  size='xs'  mt={1} isAttached variant='outline'>
              <Center minW="2">
                <Text as="span" mb={1} fontWeight="semibold" userSelect="none" ml={3} mr={3}>
                  {quantity}
                </Text>
              </Center>
          </ButtonGroup> 
        </Text>
        <Text flex="1" fontWeight="bold"><PriceTag  price={price} currency={currency1} /></Text>
        {trans_type == 22 && <>
        <Text flex="1" fontWeight="bold">{Tax}</Text>
        <Text flex="1" fontWeight="bold">{TotalTax}</Text>
        <Text flex="1" fontWeight="bold"><PriceTag price={Total} currency={currency1} /></Text></>
        }
      </Flex>

      {/* Mobile */}
      <Flex
        mt="4"
        align="center"
        width="full"
        justify="space-between"
        display={{
          base: 'flex',
          md: 'none',
        }}
      >
        
        {/* <Button size="sm" colorScheme='red' onClick={() => dispatch(removeItem(master_key))} >
          Delete
        </Button> */}
          <ButtonGroup size='sm' isAttached variant='outline'>
            {/* <Button
              onClick={() => dispatch(decrementQuantity(master_key))}
            >
              <FiMinus />
            </Button> */}
            <Center minW="1">
              <Text as="span" fontWeight="semibold" userSelect="none" ml={3} mr={3}>
                Qty : {quantity}
              </Text>
            </Center>
            <Center minW="1">
              <Text as="span" fontWeight="semibold" userSelect="none" ml={3} mr={3}>
                Price : {price}
              </Text>
            </Center>
            {trans_type == 22 &&
              <>
                <Center minW="1">
                  <Text as="span" fontWeight="semibold" userSelect="none" ml={3} mr={3}>
                    Tax % {Tax}
                  </Text>
                </Center>
                <Center minW="1">
                <Text as="span" fontWeight="semibold" userSelect="none" ml={3} mr={3}>
                  Tax {TotalTax}
                </Text>
                </Center>
                <Center minW="1">
                <Text as="span" fontWeight="semibold" userSelect="none" ml={3} mr={3}>
                  Total {Total}
                  </Text>
                </Center>
              </>
            }
            {/* <Button
              onClick={() => dispatch(incrementQuantity(master_key))}
            >
            <FiPlus />
            </Button> */}
          </ButtonGroup>
        {/* <QuantitySelect
          value={quantity}
          onChange={(e) => {
            onChangeQuantity?.(+e.currentTarget.value)
          }}
        /> */}
        
        
      </Flex>
    </Flex>
    </>
  )
}

