import React,{useEffect} from 'react';
import {
  Button,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
  useMediaQuery
} from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'
import { formatPrice } from './PriceTag'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const CartOrderSummary = ({cart_array}) => {
  const [disablebtn, setDisableBtn] = React.useState(true);
  const [isDesktop] = useMediaQuery("(min-width: 1024px)");
  const marginValue = isDesktop ? "100px" : "10px";
  let navigate = useNavigate();
  useEffect(()=>{
    if(cart_array.length > 0){
      setDisableBtn(false);
    }else{
      setDisableBtn(true);
    }
  },[cart_array]);
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;
    let grandtotal = 0;
    cart_array.forEach(item => {
      totalQuantity += item.quantity;
      totalPrice += (item.price * item.quantity);
      grandtotal +=  item.Total;
    })
    return { totalPrice, totalQuantity,grandtotal }
  }
  const priceVal = getTotal().totalPrice;
  const priceGrandTot = getTotal().grandtotal;
  return (
    <Stack spacing="8" marginTop={marginValue} borderWidth="1px" rounded="lg" padding="8" width="full">
      <Heading size="md">Order Summary</Heading>

      <Stack spacing="6">
        {/* <OrderSummaryItem label="Subtotal" value={formatPrice(priceVal)}  /> */}
        <Flex justify="space-between">
          <Text fontSize="lg" fontWeight="semibold">
            Total
          </Text>
          <Text fontSize="xl" fontWeight="extrabold">
            {formatPrice(priceVal)}
          </Text>
        </Flex>
      </Stack>
      <Button onClick={()=> navigate("/checkoutcart")} disabled={disablebtn} colorScheme="green" size="md" borderRadius={0} fontSize="md" rightIcon={<FaArrowRight />} >
        Checkout
      </Button>
    </Stack>
  )
}
