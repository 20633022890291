import React from 'react';
import { useNavigate,useLocation  } from 'react-router-dom';
import {
    Flex
} from '@chakra-ui/react';
import { Link } from '@chakra-ui/react'
import './style.css';
import { useSelector } from 'react-redux';



export default function DynamicButton() {
    let navigate = useNavigate();
    const location = useLocation();
    const url_str = location.pathname;//history.location.pathname;
    var myArray = url_str.split('/');
    const cat_active = myArray[1] == "category" ? "active" : "";
    const prod_active = (myArray[1] == "product_list" || myArray[1] == "product") ? "active" : "";
    const cart_active = myArray[1] == "cart" ? "active" : "";
    const baseurl = useSelector(state => state.baseurl.baseURL);; //window.location.origin; //"https://gadgets.ondemandcrm.co";

    const IMAGE = `${baseurl}/public/images/MedBot/dashboard.svg`;
    const IMAGE1 = `${baseurl}/public/images/MedBot/product.svg`;
    const IMAGE2 = `${baseurl}/public/images/MedBot/cart.svg`;
    return (
    <>
       <Flex
        align={'center'}
        justify={'center'}
      >
        <div className="bottom-nav">
            <ul className="mb-0 p-0">
                {/* /ecommerce Remove  */}
                <li className={`${cat_active}`}>
                    <Link  onClick={()=> navigate("/category")}>
                        <img src={IMAGE} alt=""/><span>Category</span>
                    </Link>
                </li>
                {/* /ecommerce Remove  */}
                <li className={`${prod_active}`}>
                    <Link onClick={()=> navigate("/filter/ALL")}>
                        <img src={IMAGE1} alt=""/>
                        <span>Products</span>
                    </Link>
                </li>
                {/* /ecommerce Remove  */}
                <li className={`${cart_active}`}>
                    <Link onClick={()=> navigate("/cart")}>
                        <img src={IMAGE2} alt=""/>
                        <span>Cart</span>
                    </Link>
                </li>
            </ul>
        </div>
    </Flex>
    </>
  )
}
